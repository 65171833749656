import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dotdotdot from 'react-dotdotdot'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { trackWindowScroll } from 'react-lazy-load-image-component'
import LazyLoadImageContainer from '../LazyLoadImageContainer'
import SponsoredLabel from './SponsoredLabel'
import setLastSKU from '../../actions/setLastSKU'
import RctCard from '../RctCards/RctCard'
import PricePerUm from '../../functional-components/ProductPrices/components/PricePerUm'
import { ORIGINS, sendproductClickToAnalytics, generateAnalyticsListNames } from '../../common/GoogleTagManager'

import CampaignTag from '../../functional-components/Tags/components/CampaignTag'
import AttributeTag from '../../functional-components/Tags/components/AttributeTag'
import DeliveryTag from '../../functional-components/Tags/components/DeliveryTag'
import ProductCardButton from './ProductCardButton'
import Price from '../../functional-components/ProductPrices/components/Price'
import RegularUnitPrice from '../../functional-components/ProductPrices/components/RegularUnitPrice'
import CompleteProductPack from '../../functional-components/CompleteProductPack'
import ProductColorSelection from '../../functional-components/ProductColorSelection'
import ReferencePrice from './ReferencePrice'
import DiscountPercentage from './DiscountPercentage'
import { isCatex, isProductPack, getItemSavingAmount } from '../../Helpers'
import { TenantContext } from '../../contexts/TenantContext'
import constants from '../../common/constants'
import texts from '../../common/texts.json'
import './index.css'
import { sendSPAClickEvent, sendSPAImpressionEvent, sendTaggingClick } from '../../common/advisorSPAUtil'
import ClientAPI from '../../common/ClientAPI'
import AdvertisingProduct from '../../functional-components/Advertising/AdvertisingProduct'
import getProductPathname from '../../Helpers/getProductPathname'
import InlineRating from '../RatingsReviews/InlineRating'
import ProductSeller from '../../functional-components/ProductSeller'
import InternationalSeller from '../../functional-components/InternationalSeller'
import SavingsLabel from '../../functional-components/SavingsLabel'

class ProductCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      categoriesRefinement: '',
      categoryType: '',
      eventImpressionSent: false,
    }
    this.configData = window.__ENV__
    this.clientAPI = new ClientAPI()
  }

  componentDidMount() {
    const { categoriesRefinement, categoryType } = generateAnalyticsListNames()
    this.setState({ categoriesRefinement, categoryType })
  }

  getDiscount = (item) => {
    const normalPrice = parseInt(item.price.BasePriceReference, 0)
    const actualPrice = parseInt(item.price.BasePriceSales, 0)
    if (normalPrice > 0 && actualPrice > 0) {
      return 100 - Math.round((actualPrice * 100) / normalPrice)
    }
    return 0
  }

  handleSetLastSKU = (hit) => {
    const { setLastSKU } = this.props
    setLastSKU(hit.sku)
  }

  showLoader() {
    this.setState({ showLoader: true }, () => {
      setTimeout(() => {
        this.setState({ showLoader: false })
      }, 5000)
    })
  }

  sendImpression(hit, metadata) {
    if (!this.state.eventImpressionSent) {
      sendSPAImpressionEvent(hit, metadata)
      this.setState({ eventImpressionSent: true })
    }
  }

  render() {
    const {
      hit,
      origin,
      index,
      campaignConfig,
      setClickedSKU,
      carouselMaxWidth,
      scrollPosition,
      shoppingCart,
      flowType,
      dtSearchEngine,
      shouldRenderVariants,
      searchState,
      showWfsTooltip,
      isMobile,
    } = this.props
    const { showLoader, categoriesRefinement, categoryType } = this.state
    let TLMCMarginTop = '-1px'
    if (origin !== ORIGINS.HOME_PAGE) {
      TLMCMarginTop = '-4px'
    }

    const { tenant } = this.context
    const isSod = tenant === constants.tenant.SOD

    const isWalstore = this.configData?.isWalstore === 'true'
    const isMarketplaceEnabled = campaignConfig?.enableMarketplace && isCatex() && !isWalstore

    const isRatingsAndReviewsEnabled = campaignConfig?.ratingsReviews?.enabled

    const basePriceReference = Number(hit.price.BasePriceReference)
    const basePriceSales = Number(hit.price.BasePriceSales)
    const basePricePerUm = hit.price.BasePricePerUm

    const { attributes = {} } = hit
    const { soldByWeight = 'false' } = attributes

    const shouldDisplayUm = isSod && basePricePerUm && basePricePerUm !== '0' && soldByWeight && soldByWeight == 'true'
    const pricePerUmClass = isMobile
      ? 'price-per-um__plp price-per-um__plp--mobile'
      : 'price-per-um__plp price-per-um__plp--desktop'

    const isProductAvailable = hit.available
    const discountPercentage = this.getDiscount(hit)
    const { MINIMUM_DISCOUNT_PERCENTAGE } = constants

    const packPrice = Number(hit.price.packPrice)
    const packSize = Number(hit.price.packSize)
    const isXxYPromotion = (isSod || campaignConfig?.promotions?.enabledCatexXxYPack) && isProductPack(hit.price)
    const tlmcPrice = parseInt(hit.price.BasePriceTLMC, 0)
    const showTLMCPrice = !isXxYPromotion && tlmcPrice > 0 && (isCatex() || campaignConfig?.promotions?.enabledBciSod)
    const showReferencePrice = !isXxYPromotion && basePriceReference > basePriceSales
    const showDiscountPercentage = !isXxYPromotion && discountPercentage > MINIMUM_DISCOUNT_PERCENTAGE
    const productSalePrice = isXxYPromotion ? packPrice : basePriceSales
    const productPackQuantity = isXxYPromotion ? packSize : 0
    const productBlockClass = `${isCatex() ? ' product-block-catex' : 'product-block'}`

    const shoppingCartItem = shoppingCart.find((item) => item.sku === hit.sku)

    const showVariants = Array.isArray(hit?.variants)
    const isCombinableProduct = hit?.promotion?.isCombinable && isProductPack(hit.price)

    const { images = {}, sku, slug, tags = {}, winningOffer } = hit

    const { deliveryTags = [] } = tags
    const { defaultImage: hitImage } = images

    if (hit.tracking) {
      this.sendImpression(hit, { query: searchState.query, position: index })
    }

    const productCard = (
      <RctCard
        customClasses={`${
          carouselMaxWidth ? 'carousel-max-width ' : ''
        } ${productBlockClass} flex-column justify-content-between relative overflow-hidden bg-white rct-block__hover-shadow`}
      >
        <NavLink
          data-testid="product-card-nav-test-id"
          style={{
            textDecoration: 'none',
            height: '89%',
            width: '100%',
            display: 'inline',
            position: 'absolute',
            zIndex: 1,
          }}
          to={{
            pathname: getProductPathname({ sku, slug }),
            state: {
              origin,
              hit,
              flowType,
              dtSearchEngine,
              searchState,
              plpPosition: index,
            },
          }}
          onClick={() => {
            if (hit.tracking) {
              sendSPAClickEvent(hit)
              sendTaggingClick(hit, { origin: ORIGINS.PDP, query: searchState.query, position: index })
            } else {
              sendproductClickToAnalytics(hit, categoriesRefinement, categoryType, index)
            }
            this.showLoader()
            if (setClickedSKU) {
              this.handleSetLastSKU(hit)
            }
          }}
        />

        <div id={hit.sku} className="product-card__image-area overlay-wrap overflow-hidden bg-white">
          <div className="text-center">
            {showLoader && (
              <div className="product-loader-container">
                <CircularProgress
                  className="progress-primary"
                  size={26}
                  mode="determinate"
                  value={75}
                  style={{ color: '#0071ce' }}
                />
              </div>
            )}

            <div className="product-card__tags-container">
              <CampaignTag tags={hit.tags} isAvailable={hit.available} />
            </div>

            <div id="image" className="bg-white" data-testid="product-card-image-test-id">
              <LazyLoadImageContainer
                id="lazy-img"
                className={`${isProductAvailable ? 'img-hover-zoom ' : ''}img-fluid m-auto `}
                alt={`${hit.brand} - ${hit.displayName}`}
                sku={hit.sku}
                hitImage={hitImage}
                style={
                  !isProductAvailable
                    ? {
                        opacity: '0.4',
                        filter: 'grayscale(1)',
                        minWidth: '50px',
                        height: '150px',
                      }
                    : { filter: 'unset', minWidth: '50px', height: '150px' }
                }
                scrollPosition={scrollPosition}
              />
            </div>
            <div className="product-card__tags-container">
              <AttributeTag tags={hit.tags} isAvailable={hit.available} />
            </div>
          </div>
          <div className="product-image-shadow" />
        </div>

        {shouldRenderVariants && (
          <ProductColorSelection
            variants={hit.variants}
            attributes={hit.attributes}
            parentSku={hit.sku}
            slug={hit.slug}
            showColorSelection={showVariants}
          />
        )}

        <div className="product-info">
          {isSod && (
            <SponsoredLabel isSponsored={hit.tracking} isAvailable={hit.available} origin={this.props.origin} />
          )}
          <h2
            className={`d-flex text-left product-description ${!isProductAvailable ? ' product-disabled' : ''} ${
              isSod ? 'mb-0' : ''
            }`}
          >
            <div className="product-card_description-wrapper">
              <Dotdotdot clamp={2}>
                <span
                  className={!isProductAvailable ? 'product-disabled' : ''}
                  style={{ fontWeight: 'bold', color: '#000' }}
                >
                  {hit.brand}
                </span>
                <span>{` ${hit.displayName}`}</span>
              </Dotdotdot>
            </div>
          </h2>

          <div>{isRatingsAndReviewsEnabled && <InlineRating productId={hit.sku} showCommentButton={false} />}</div>

          {!isSod && (
            <div className={'product-card__seller-container'}>
              <ProductSeller
                isWfsProduct={hit.wfsEligible}
                sellerName={hit.isMKP ? hit?.winningOffer?.sellerName : texts.COMMON.SOLD_BY_WALMART_LABEL}
                showIcon={false}
                showDetails={false}
                alwaysShowTwoLines={true}
                showTooltip={showWfsTooltip}
                customClass={`${!isProductAvailable ? 'product-disabled' : ''} product-card__seller-label`}
              />
            </div>
          )}

          <div>
            {showTLMCPrice && (
              <div
                className={`d-flex walmart-card-price${!isProductAvailable ? ' product-disabled' : ''}`}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                ${parseInt(hit.price.BasePriceTLMC, 0).toLocaleString('es-CL').replace(/,/g, '.')}
                <span style={{ marginTop: TLMCMarginTop, marginLeft: '6px' }}>
                  <img
                    style={
                      !isProductAvailable
                        ? {
                            opacity: '0.4',
                            filter: 'grayscale(1)',
                          }
                        : { filter: 'none' }
                    }
                    alt="lider-card"
                    src="/catalogo/images/lider-card.svg"
                    width="27"
                    height="17"
                  />
                </span>
              </div>
            )}

            <div
              className={`walmart-sales-price d-flex ${!isProductAvailable ? ' product-disabled' : ''}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className="product-card__sale-price">
                <Price price={productSalePrice} quantity={productPackQuantity} />
              </div>

              {showDiscountPercentage && isCatex() && (
                <DiscountPercentage isAvailable={isProductAvailable} discountPercentage={discountPercentage} />
              )}
              {showDiscountPercentage && isSod && (
                <SavingsLabel
                  savings={getItemSavingAmount(hit.price)}
                  classNames={`ml-2 ${isProductAvailable ? '' : 'savings-label--disabled'}`}
                />
              )}
            </div>

            {isXxYPromotion && <RegularUnitPrice price={basePriceSales} location="product-card" />}

            {showReferencePrice && (
              <ReferencePrice isAvailable={isProductAvailable} referencePrice={basePriceReference} />
            )}
            {shouldDisplayUm && <PricePerUm price={basePricePerUm} priceClass={pricePerUmClass} />}

            {isCatex() && winningOffer?.internationalSeller && <InternationalSeller showModal={false} theme="blue" />}
          </div>
          {!isSod && (
            <div
              data-testid="delivery-label-test-renderer"
              className={`d-flex w-100${isMarketplaceEnabled ? ' product-card_tags-wrapper' : ''}`}
              style={{ position: 'absolute', bottom: '56px' }}
            >
              {isProductAvailable && deliveryTags.map((tag, index) => <DeliveryTag key={index} tag={tag} />)}
            </div>
          )}
        </div>

        {isXxYPromotion && shoppingCartItem && !isCombinableProduct && (
          <CompleteProductPack
            quantity={shoppingCartItem.quantity}
            packPrice={packPrice}
            packSize={packSize}
            regularUnitPrice={basePriceSales}
          />
        )}
        <ProductCardButton
          hit={hit}
          origin={origin}
          flowType={flowType}
          campaignConfig={campaignConfig}
          dtSearchEngine={dtSearchEngine}
          searchState={searchState}
          index={index}
        />
      </RctCard>
    )
    return hit.tracking ? (
      <AdvertisingProduct hit={hit} searchState={searchState} position={index}>
        {productCard}
      </AdvertisingProduct>
    ) : (
      productCard
    )
  }
}

ProductCard.contextType = TenantContext

const mapDispatchToProps = (dispatch) => ({
  setLastSKU: (item) => dispatch(setLastSKU(item)),
})

const mapStateToProps = (state) => ({
  campaignConfig: state.campaignConfigReducer,
  shoppingCart: state.shoppingCartReducer.shoppingCart,
})

export default connect(mapStateToProps, mapDispatchToProps)(trackWindowScroll(ProductCard))

ProductCard.defaultProps = {
  hit: {},
  index: 0,
  origin: '',
  campaignConfig: {},
  setClickedSKU: false,
  carouselMaxWidth: false,
  scrollPosition: {},
  shoppingCart: [],
  flowType: '',
  dtSearchEngine: '',
  searchState: {},
  shouldRenderVariants: false,
  showWfsTooltip: false,
  isMobile: false,
}

ProductCard.propTypes = {
  carouselMaxWidth: PropTypes.bool,
  hit: PropTypes.object,
  campaignConfig: PropTypes.object,
  origin: PropTypes.string,
  setLastSKU: PropTypes.func.isRequired,
  setClickedSKU: PropTypes.bool,
  scrollPosition: PropTypes.object,
  index: PropTypes.number,
  shoppingCart: PropTypes.array,
  flowType: PropTypes.string,
  dtSearchEngine: PropTypes.string,
  searchState: PropTypes.object,
  shouldRenderVariants: PropTypes.bool,
  showWfsTooltip: PropTypes.bool,
  isMobile: PropTypes.bool,
}
