import React from 'react'
import PropTypes from 'prop-types'

import './PricePerUm.css'

const PricePerUm = ({ price, priceClass }) => (
  <div className={`d-flex ${priceClass}`}>
    {price}
  </div>
)

PricePerUm.propTypes = {
  price: PropTypes.string.isRequired,
  priceClass: PropTypes.string,
}

PricePerUm.defaultProps = {
  priceClass: 'price-per-um__pdp price-per-um__pdp--desktop',
}

export default PricePerUm
